<template>
  <div v-if="elementData && elementData.arl">
    <b-card>
      <b-card-header>
        <div class="text-primary h2">
          <feather-icon
            icon="StarIcon"
            size="25"
            class="mr-0 mr-sm-50"
            variant="primary"
          />
          <span class="font-weight-bold">{{ $t(elementName + '.name') }}</span>
        </div>
      </b-card-header>
      <b-card-body>

        <b-row>
          <!-- Right Col: Table -->
          <b-col
            cols="12"
            xl="6"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.arl') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.arl.name }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.service_type') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.type.value }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.cups_cum_name') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.service.cups }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.cups_cum') }}</span>
                </th>
                <td class="pb-50">
                  {{ elementData.service.cups }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.cost') }}</span>
                </th>
                <td class="pb-50">
                  $ {{ elementData.cost }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.date_from') }}</span>
                </th>
                <td class="pb-50">
                  {{ formatDate(elementData.date_from) }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(elementName+'.object.date_to') }}</span>
                </th>
                <td class="pb-50">
                  {{ formatDate(elementData.date_to) }}
                </td>
              </tr>

            </table>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="router().go(-1)"
          >
            {{ $t('actions.back') }}
          </b-button>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import moment from 'moment'
import router from '@/router'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BCardHeader, BCardBody,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    elementName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      formatDate(field) {
        return moment(field, 'DD/MM/YYYY').format('DD/MM/YYYY')
      },
      getTypeValue(element, type) {
        return element[type] ? element[type].value : ''
      },
    }
  },
  methods: {
    router() {
      return router
    },
  },
}
</script>

<style>

</style>
